import type { MetaFunction } from "@remix-run/node";

const meta: MetaFunction = ({ params }) => {
  const title: string = `G2X - The Growth Platform for Government Contractors`;

  return [
    { charSet: "utf-8" },
    { title },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, shrink-to-fit=no",
    }, // Updated here
    { name: "theme-color", content: "#0e214b" },
  ];
};

export default meta;
